export const products21 = [
  {
    id: 267,
    imgSrc: "/assets/images/home/demo11/product-1.jpg",
    category: "Television & Video",
    title: "Fire TV Stick 4K streaming device with Alexa built in",
    stars: 5,
    reviews: 321975,
    price: 35.9,
    available: 6,
    sold: 28,
  },
  {
    id: 268,
    imgSrc: "/assets/images/home/demo11/product-2.jpg",
    category: "Television & Video",
    title: "Letsfit Smart Watch, Fitness Tracker with Heart Rate",
    stars: 5,
    reviews: 321975,
    price: 79.99,
    available: 6,
    sold: 28,
  },
  {
    id: 269,
    imgSrc: "/assets/images/home/demo11/product-3.jpg",
    category: "Television & Video",
    title: "Acer Aspire 5 Slim Laptop, 15.6 inches Full HD IPS",
    stars: 5,
    reviews: 321975,
    price: 929,
    available: 6,
    sold: 28,
  },
  {
    id: 270,
    imgSrc: "/assets/images/home/demo11/product-4.jpg",
    category: "Television & Video",
    title: "Apple iPhone 11 Pro Max (64GB, Midnight Green)",
    stars: 5,
    reviews: 321975,
    price: 729,
    available: 6,
    sold: 28,
  },
  {
    id: 271,
    imgSrc: "/assets/images/home/demo11/product-5.jpg",
    category: "Television & Video",
    title: "DJI Mavic Mini - Drone FlyCam Quadcopter UAV",
    stars: 5,
    reviews: 321975,
    price: 399,
    available: 6,
    sold: 28,
  },
  {
    id: 272,
    imgSrc: "/assets/images/home/demo11/product-1.jpg",
    category: "Television & Video",
    title: "Fire TV Stick 4K streaming device with Alexa built in",
    stars: 5,
    reviews: 321975,
    price: 35.9,
    available: 6,
    sold: 28,
  },
  {
    id: 273,
    imgSrc: "/assets/images/home/demo11/product-2.jpg",
    category: "Television & Video",
    title: "Letsfit Smart Watch, Fitness Tracker with Heart Rate",
    stars: 5,
    reviews: 321975,
    price: 79.99,
    available: 6,
    sold: 28,
  },
  {
    id: 274,
    imgSrc: "/assets/images/home/demo11/product-3.jpg",
    category: "Television & Video",
    title: "Acer Aspire 5 Slim Laptop, 15.6 inches Full HD IPS",
    stars: 5,
    reviews: 321975,
    price: 929,
    available: 6,
    sold: 28,
  },
  {
    id: 275,
    imgSrc: "/assets/images/home/demo11/product-4.jpg",
    category: "Television & Video",
    title: "Apple iPhone 11 Pro Max (64GB, Midnight Green)",
    stars: 5,
    reviews: 321975,
    price: 729,
    available: 6,
    sold: 28,
  },
  {
    id: 276,
    imgSrc: "/assets/images/home/demo11/product-5.jpg",
    category: "Television & Video",
    title: "DJI Mavic Mini - Drone FlyCam Quadcopter UAV",
    stars: 5,
    reviews: 321975,
    price: 399,
    available: 6,
    sold: 28,
  },
];

export const products22 = [
  {
    id: 277,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 278,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 279,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 280,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 281,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 282,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 283,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 284,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 285,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 286,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 287,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 288,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 289,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 290,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 291,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 292,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "TV/Audio",
  },
  {
    id: 293,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 294,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 295,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 296,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "Cameras",
  },
  {
    id: 297,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision",
    price: 72.97,
    imgSrc: "/assets/images/home/demo11/product-7.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 298,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-9.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 299,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-6.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
  {
    id: 300,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming",
    price: 36.4,
    imgSrc: "/assets/images/home/demo11/product-8.jpg",
    reviews: 321975,
    filterCategory: "Video",
  },
];
export const products23 = [
  {
    id: 301,
    category: "Smart Home",
    title:
      "All-new Echo (4th Gen) | With premium sound, smart home hub, and Alexa | Charcoal",
    price: 99.99,
    imgSrc: "/assets/images/home/demo11/product-10.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Top New",
  },
  {
    id: 302,
    category: "Television & Video",
    title: "Sure Petcare -SureFlap - SureFeed - Microchip Pet",
    price: 929,
    imgSrc: "/assets/images/home/demo11/product-11.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Featured",
  },
  {
    id: 303,
    category: "Television & Video",
    title: "ecobee EB-STATe3L-01 3 Lite Thermostat, Wi-Fi, Works",
    price: 729,
    imgSrc: "/assets/images/home/demo11/product-12.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Featured",
  },
  {
    id: 304,
    category: "Television & Video",
    title: "Anova Culinary AN500-US00 Sous Vide Precision",
    price: 399,
    imgSrc: "/assets/images/home/demo11/product-13.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Featured",
  },
  {
    id: 305,
    category: "Television & Video",
    title: "Sonos One SL - Microphone-Free Smart",
    price: 929,
    imgSrc: "/assets/images/home/demo11/product-14.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Top New",
  },
  {
    id: 306,
    category: "Smart Home",
    title:
      "All-new Echo (4th Gen) | With premium sound, smart home hub, and Alexa | Charcoal",
    price: 99.99,
    imgSrc: "/assets/images/home/demo11/product-10.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Top New",
  },
  {
    id: 307,
    category: "Television & Video",
    title: "Sure Petcare -SureFlap - SureFeed - Microchip Pet",
    price: 929,
    imgSrc: "/assets/images/home/demo11/product-11.jpg",
    altText: "",
    reviews: 321975,
    filterCategory: "Featured",
  },
];
export const products24 = [
  {
    id: 308,
    imgSrc: "/assets/images/home/demo11/product-17.jpg",
    category: "Television & Video",
    title: "Samsung Galaxy Watch 3 Titanium (45mm, GPS)",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 35.9,
  },
  {
    id: 309,
    imgSrc: "/assets/images/home/demo11/product-18.jpg",
    category: "Television & Video",
    title: "Xbox One S 1TB Console [Previous Generation]",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 79.99,
  },
  {
    id: 310,
    imgSrc: "/assets/images/home/demo11/product-19.jpg",
    category: "Television & Video",
    title: "Logitech C920x Pro HD Webcam",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 929,
  },
  {
    id: 311,
    imgSrc: "/assets/images/home/demo11/product-20.jpg",
    category: "Television & Video",
    title: "Google S3000BWES Nest Protect Alarm-Smoke",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 929,
  },
  {
    id: 312,
    imgSrc: "/assets/images/home/demo11/product-17.jpg",
    category: "Television & Video",
    title: "Samsung Galaxy Watch 3 Titanium (45mm, GPS)",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 35.9,
  },
  {
    id: 313,
    imgSrc: "/assets/images/home/demo11/product-18.jpg",
    category: "Television & Video",
    title: "Xbox One S 1TB Console [Previous Generation]",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 79.99,
  },
  {
    id: 314,
    imgSrc: "/assets/images/home/demo11/product-19.jpg",
    category: "Television & Video",
    title: "Logitech C920x Pro HD Webcam",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 929,
  },
  {
    id: 315,
    imgSrc: "/assets/images/home/demo11/product-20.jpg",
    category: "Television & Video",
    title: "Google S3000BWES Nest Protect Alarm-Smoke",
    reviewStars: 5,
    reviewsCount: 321975,
    price: 929,
  },
];
