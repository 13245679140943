export const homePages = [
  { id: 1, title: "Home 1", href: "/" },
  { id: 2, title: "Home 2", href: "/home-2" },
  { id: 3, title: "Home 3", href: "/home-3" },
  { id: 4, title: "Home 4", href: "/home-4" },
  { id: 5, title: "Home 5", href: "/home-5" },
  { id: 6, title: "Home 6", href: "/home-6" },
  { id: 7, title: "Home 7", href: "/home-7" },
  { id: 8, title: "Home 8", href: "/home-8" },
  { id: 9, title: "Home 9", href: "/home-9" },
  { id: 10, title: "Home 10", href: "/home-10" },
  { id: 11, title: "Home 11", href: "/home-11" },
  { id: 12, title: "Home 12", href: "/home-12" },
  { id: 13, title: "Home 13", href: "/home-13" },
  { id: 14, title: "Home 14", href: "/home-14" },
  { id: 15, title: "Home 15", href: "/home-15" },
  { id: 16, title: "Home 16", href: "/home-16" },
  { id: 17, title: "Home 17", href: "/home-17" },
  { id: 18, title: "Home 18", href: "/home-18" },
  { id: 19, title: "Home 19", href: "/home-19" },
  { id: 20, title: "Home 20", href: "/home-20" },
  { id: 21, title: "Home 21", href: "/home-21" },
  { id: 22, title: "Home 22", href: "/home-22" },
];

export const shopList = [
  {
    id: 1,
    href: "/shop-8?category=eau-de-parfum&subcategory=oriental-fragrance",
    title: "Oriental Fragrance"
  },
  {
    id: 2,
    href: "/shop-8?category=eau-de-parfum&subcategory=occidental-fragrance",
    title: "Occidental Fragrance"
  },
  // {
  //   id: 3,
  //   href: "/shop-3",
  //   title: "Shop List V3",
  // },
  // {
  //   id: 4,
  //   href: "/shop-4",
  //   title: "Shop List V4",
  // },
  // {
  //   id: 5,
  //   href: "/product-category/gift-sets",
  //   title: "Shop List V5",
  // },
  // {
  //   id: 6,
  //   href: "/shop-6",
  //   title: "Shop List V6",
  // },
  // {
  //   id: 7,
  //   href: "/shop-7",
  //   title: "Shop List V7",
  // },
  // {
  //   id: 8,
  //   href: "/shop-8",
  //   title: "Shop List V8",
  // },
  // {
  //   id: 9,
  //   href: "/shop-9",
  //   title: "Shop List V9",
  // },
  // {
  //   id: 10,
  //   href: "/shop",
  //   title: "Shop Item Style",
  // },
  // {
  //   id: 11,
  //   href: "/shop",
  //   title: "Horizontal Scroll",
  // },
];
export const shopDetails = [
  { id: 1, href: "/shop-8?category=concentrated-parfum&subcategory=dehn-al-oud", title: "Dehn Al Oud" },
  { id: 2, href: "/shop-8?category=concentrated-parfum&subcategory=concentrated-oil", title: "Concentrated Oil" },
  // { id: 3, href: "/product8_v3/1", title: "Shop Detail V3" },
  // { id: 4, href: "/product9_v4/1", title: "Shop Detail V4" },
  // { id: 5, href: "/product10_v5/1", title: "Shop Detail V5" },
  // { id: 6, href: "/product11_v6/1", title: "Shop Detail V6" },
  // { id: 7, href: "/product12_v7/1", title: "Shop Detail V7" },
  // { id: 8, href: "/product13_v8/1", title: "Shop Detail V8" },
  // { id: 9, href: "/product14_v9/1", title: "Shop Detail V9" },
  // { id: 10, href: "/product15_v10/1", title: "Shop Detail V10" },
  // { id: 11, href: "/product16_v11/1", title: "Shop Detail V11" },
];

export const additionalShopPageitems = [
  { id: 1, href: "/shop-8?category=dakhoon&subcategory=bakhoor", title: "Bakhoor" },
  { id: 2, href: "/shop-8?category=dakhoon&subcategory=natural-oud", title: "Natural Oud" },
  { id: 3, href: "/shop-8?category=dakhoon&subcategory=oud-maattar", title: "Oud Ma'attar" },
  { id: 4, href: "/shop-8?category=dakhoon&subcategory=air-freshener", title: "Air Freshener" },
  // { id: 5, href: "/product4_grouped/2", title: "Grouped Product" },
  // { id: 6, href: "/product5_onsale/2", title: "On Sale" },
  // { id: 7, href: "/product6_outofstock/2", title: "Out of Stock" },
  // { id: 8, href: "/shop-cart", title: "Shopping Cart" },
  // { id: 9, href: "/shop-checkout", title: "Checkout" },
  // { id: 10, href: "/shop-order-complete", title: "Order Complete" },
  // { id: 11, href: "/shop-order-tracking", title: "Order Tracking" },
];

export const blogmenuItems = [
  {
    id: 1,
    href: "/product-category/gift-sets?category=gift-sets&subcategory=gift-sets",
    title: "Gift Sets",
  },
  // {
  //   id: 2,
  //   href: "/blog_list2",

  //   title: "Blog V2",
  // },
  // {
  //   id: 3,
  //   href: "/blog_list3",

  //   title: "Blog V3",
  // },
  // {
  //   id: 4,
  //   href: "/blog_single/1",

  //   title: "Blog Detail",
  // },
];

export const othersMenuItems = [
  {
    id: 1,
    href: "/shop-8?category=gel&subcategory=body-gel",
    title: "Body Gel",
  },
  // {
  //   id: 2,
  //   href: "/login_register",
  //   title: "Login / Register",
  // },
  // {
  //   id: 3,
  //   href: "/store_location",
  //   title: "Store Locator",
  // },
  // {
  //   id: 4,
  //   href: "/lookbook",
  //   title: "Lookbook",
  // },
  // {
  //   id: 5,
  //   href: "/faq",
  //   title: "Faq",
  // },
  // {
  //   id: 6,
  //   href: "/terms",
  //   title: "Terms",
  // },
  // {
  //   id: 7,
  //   href: "/page-not-found",
  //   title: "404 Error",
  // },
  // {
  //   id: 8,
  //   href: "/coming_soon",
  //   title: "Coming Soon",
  // },
];

export const collections = [
  {
    id: 1,
    href: "/shop-8?category=collections&subcategory=premium-collection",
    title: "Premium Collection",
  },
  {
    id: 2,
    href: "/shop-8?category=collections&subcategory=online-exclusive-sets",
    title: "Online Exclusive Sets",
  },
  {
    id: 3,
    href: "/shop-8?category=collections&subcategory=summer-collection",
    title: "Summer Collection",
  },
  // {
  //   id: 4,
  //   href: "/lookbook",
  //   title: "Lookbook",
  // },
  // {
  //   id: 5,
  //   href: "/faq",
  //   title: "Faq",
  // },
  // {
  //   id: 6,
  //   href: "/terms",
  //   title: "Terms",
  // },
  // {
  //   id: 7,
  //   href: "/page-not-found",
  //   title: "404 Error",
  // },
  // {
  //   id: 8,
  //   href: "/coming_soon",
  //   title: "Coming Soon",
  // },
];

export const dashboardMenuItems = [
  {
    id: 1,
    href: "/account_dashboard",
    title: "Dashboard",
  },
  {
    id: 2,
    href: "/account_orders",
    title: "Orders",
  },
  {
    id: 3,
    href: "/account_edit_address",
    title: "Addresses",
  },
  {
    id: 4,
    href: "/account_edit",
    title: "Account Details",
  },
  {
    id: 5,
    href: "/account_wishlist",
    title: "Wishlist",
  },
  {
    id: 6,
    href: "/login_register",
    title: "Logout",
  },
];
