export const products1 = [
  {
    id: 1,
    imgSrc: "/assets/images/home/demo1/product-0-1.jpg",
    imgAlt: "Cropped Faux leather Jacket",
    category: "Dresses",
    title: "Oud & Roses",
    price: 17,
    product_name: "",
    images: "[\"products\\/oud-and-lavender.webp\",\"products\\/oud-and-lavender.webp\"]"
  },
  // {
  //   id: 2,
  //   imgSrc: "/assets/images/home/demo1/product-1-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Hosking Blue Area Rug",
  //   price: 29,
  //   reviews: "8k+ reviews",
  // },
  // {
  //   id: 3,
  //   imgSrc: "/assets/images/home/demo1/product-2-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Hanneman Pouf",
  //   price: 62,
  // },
  // {
  //   id: 4,
  //   imgSrc: "/assets/images/home/demo1/product-3-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Cushion Futon Slipcover",
  //   price: 62,
  // },
  // {
  //   id: 5,
  //   imgSrc: "/assets/images/home/demo1/product-0-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Hub Accent Mirror",
  //   price: 17,
  // },
  // {
  //   id: 6,
  //   imgSrc: "/assets/images/home/demo1/product-1-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Hosking Blue Area Rug",
  //   price: 29,
  //   reviews: "8k+ reviews",
  // },
  // {
  //   id: 7,
  //   imgSrc: "/assets/images/home/demo1/product-2-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Hanneman Pouf",
  //   price: 62,
  // },
  // {
  //   id: 8,
  //   imgSrc: "/assets/images/home/demo1/product-3-1.jpg",
  //   imgAlt: "Cropped Faux leather Jacket",
  //   category: "Dresses",
  //   title: "Cushion Futon Slipcover",
  //   price: 62,
  // },
];

export const products2 = [
  {
    id: 9,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Jeans",
  },
  {
    id: 10,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Jeans",
  },
  {
    id: 11,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Jeans",
  },
  {
    id: 12,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Jeans",
  },
  {
    id: 13,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Bags",
  },
  {
    id: 14,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Bags",
  },
  {
    id: 15,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Bags",
  },
  {
    id: 16,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Bags",
  },
  {
    id: 17,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Accessories",
  },
  {
    id: 18,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Accessories",
  },
  {
    id: 19,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Accessories",
  },
  {
    id: 20,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Accessories",
  },
  {
    id: 21,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Jeans",
  },
  {
    id: 22,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Jeans",
  },
  {
    id: 23,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Jeans",
  },
  {
    id: 24,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Jeans",
  },
  {
    id: 25,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Bags",
  },
  {
    id: 26,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Bags",
  },
  {
    id: 27,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Bags",
  },
  {
    id: 28,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Bags",
  },
  {
    id: 29,
    imgSrc: "/assets/images/home/demo2/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-1-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
    isNew: true,
    filterCategory: "Accessories",
  },
  {
    id: 30,
    imgSrc: "/assets/images/home/demo2/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-3-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    discount: -67,
    filterCategory: "Accessories",
  },
  {
    id: 31,
    imgSrc: "/assets/images/home/demo2/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-2-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Accessories",
  },
  {
    id: 32,
    imgSrc: "/assets/images/home/demo2/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo2/product-0-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Accessories",
  },
];
export const products3 = [
  {
    id: 33,
    imgSrc: "/assets/images/home/demo2/product-4-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-4-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
  },
  {
    id: 34,
    imgSrc: "/assets/images/home/demo2/product-5-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-5-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    badge: "NEW",
    price: 62,
  },
  {
    id: 35,
    imgSrc: "/assets/images/home/demo2/product-6-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-6-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
  },
  {
    id: 36,
    imgSrc: "/assets/images/home/demo2/product-7-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-7-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    discount: -67,
  },
  {
    id: 37,
    imgSrc: "/assets/images/home/demo2/product-4-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-4-2.jpg",
    category: "JEAN",
    title: "Cropped Faux Leather Jacket",
    price: 29,
  },
  {
    id: 38,
    imgSrc: "/assets/images/home/demo2/product-5-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-5-2.jpg",
    category: "JEAN",
    title: "Calvin Shorts",
    price: 62,
  },
  {
    id: 39,
    imgSrc: "/assets/images/home/demo2/product-6-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-6-2.jpg",
    category: "JEAN",
    title: "Kirby T-Shirt",
    price: 17,
  },
  {
    id: 40,
    imgSrc: "/assets/images/home/demo2/product-7-1.jpg",
    imgSecondSrc: "/assets/images/home/demo2/product-7-2.jpg",
    category: "JEAN",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    discount: -67,
  },
];
export const products4 = [
  {
    id: 41,
    imgSrc: "/assets/images/home/demo3/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-0-2.jpg",
    title: "Cropped Faux Leather Jacket",
    oldPrice: 39,
    price: 29,
  },
  {
    id: 42,
    imgSrc: "/assets/images/home/demo3/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-1-2.jpg",
    title: "Calvin Shorts",
    oldPrice: 79,
    price: 62,
  },
  {
    id: 43,
    imgSrc: "/assets/images/home/demo3/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-2-2.jpg",
    title: "Kirby T-Shirt",
    oldPrice: 79,
    price: 62,
  },
  {
    id: 44,
    imgSrc: "/assets/images/home/demo3/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-3-2.jpg",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
  },
  {
    id: 45,
    imgSrc: "/assets/images/home/demo3/product-0-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-0-2.jpg",
    title: "Cropped Faux Leather Jacket",
    oldPrice: 39,
    price: 29,
  },
  {
    id: 46,
    imgSrc: "/assets/images/home/demo3/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-1-2.jpg",
    title: "Calvin Shorts",
    oldPrice: 79,
    price: 62,
  },
  {
    id: 47,
    imgSrc: "/assets/images/home/demo3/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-2-2.jpg",
    title: "Kirby T-Shirt",
    oldPrice: 79,
    price: 62,
  },
  {
    id: 48,
    imgSrc: "/assets/images/home/demo3/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo3/product-3-2.jpg",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
  },
];
export const products5 = [
  {
    id: 49,
    title: "Cropped Faux Leather Jacket",
    imgSrc: "/assets/images/home/demo3/product-4.jpg",
    price: 29,
    isNew: false,
  },
  {
    id: 50,
    title: "Calvin Shorts",
    imgSrc: "/assets/images/home/demo3/product-5.jpg",
    price: 62,
    isNew: false,
  },
  {
    id: 51,
    title: "Kirby T-Shirt",
    imgSrc: "/assets/images/home/demo3/product-6.jpg",
    price: 17,
    isNew: true,
  },
  {
    id: 52,
    title: "Cableknit Shawl",
    imgSrc: "/assets/images/home/demo3/product-7.jpg",
    oldPrice: 129,
    price: 99,
  },
  {
    id: 53,
    title: "Cropped Faux Leather Jacket",
    imgSrc: "/assets/images/home/demo3/product-8.jpg",
    price: 29,
    isNew: false,
  },
  {
    id: 54,
    title: "Calvin Shorts",
    imgSrc: "/assets/images/home/demo3/product-9.jpg",
    price: 62,
    isNew: false,
  },
  {
    id: 55,
    title: "Kirby T-Shirt",
    imgSrc: "/assets/images/home/demo3/product-10.jpg",
    price: 17,
    isNew: false,
  },
  {
    id: 56,
    title: "Cableknit Shawl",
    imgSrc: "/assets/images/home/demo3/product-11.jpg",
    oldPrice: 129,
    price: 99,
  },
];
export const products6 = [
  {
    id: 57,
    imgSrc: "/assets/images/home/demo4/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-1-2.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Kids",
  },
  {
    id: 58,
    imgSrc: "/assets/images/home/demo4/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-2-2.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    badge: "NEW",
    hasColorVariety: true,
    colors: ["#222222", "#b9a16b", "#f5e6e0"],
    filterCategory: "Men",
  },
  {
    id: 59,
    imgSrc: "/assets/images/home/demo4/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-3-2.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Men",
  },
  {
    id: 60,
    imgSrc: "/assets/images/home/demo4/product-4-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-4-2.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Women",
  },
  {
    id: 61,
    imgSrc: "/assets/images/home/demo4/product-5-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-5-2.jpg",
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    filterCategory: "Women",
  },
  {
    id: 62,
    imgSrc: "/assets/images/home/demo4/product-6-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-6-2.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    filterCategory: "Men",
  },
  {
    id: 63,
    imgSrc: "/assets/images/home/demo4/product-7-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-7-2.jpg",
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    filterCategory: "Women",
  },
  {
    id: 64,
    imgSrc: "/assets/images/home/demo4/product-8-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-8-2.jpg",
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    filterCategory: "Kids",
  },
];

export const products7 = [
  {
    id: 65,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/home/demo4/product-1-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-1-2.jpg",
    alt: "Cropped Faux leather Jacket",
  },
  {
    id: 66,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/home/demo4/product-2-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-2-2.jpg",
    alt: "Calvin Shorts",
    hasColorVariety: true,
    colors: ["#222222", "#b9a16b", "#f5e6e0"],
    isNew: true,
  },
  {
    id: 67,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/home/demo4/product-3-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-3-2.jpg",
    alt: "Kirby T-Shirt",
  },
  {
    id: 68,
    category: "Dresses",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo4/product-4-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-4-2.jpg",
    alt: "Cableknit Shawl",
    discount: -67,
  },
  {
    id: 69,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/home/demo4/product-5-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-5-2.jpg",
    alt: "Colorful Jacket",
    isSale: true,
  },
  {
    id: 70,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/home/demo4/product-6-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-6-2.jpg",
    alt: "Shirt In Botanical Cheetah Print",
  },
  {
    id: 71,
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    imgSrc: "/assets/images/home/demo4/product-7-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-7-2.jpg",
    alt: "Cotton Jersey T-Shirt",
  },
  {
    id: 72,
    category: "Dresses",
    title: "Zessi Dresses",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo4/product-8-1.jpg",
    imgSrc2: "/assets/images/home/demo4/product-8-2.jpg",
    alt: "Zessi Dresses",
  },
];

export const products8 = [
  {
    id: 73,
    title: "Cropped Faux Leather Jacket",
    imgSrc: "/assets/images/home/demo4/product-9.jpg",
    price: 29,
    backgroundColor: "#e9e7e8",
  },
  {
    id: 74,
    title: "Calvin Shorts",
    imgSrc: "/assets/images/home/demo4/product-10.jpg",
    price: 62,
    backgroundColor: "#e9e7e8",
  },
  {
    id: 75,
    title: "Kriby T-Shirt",
    imgSrc: "/assets/images/home/demo4/product-11.jpg",
    price: 17,
    backgroundColor: "#edecea",
  },
  {
    id: 76,
    title: "Cableknit Shawl",
    imgSrc: "/assets/images/home/demo4/product-12.jpg",
    price: 129,
    backgroundColor: "#e8e8e8",
  },
  {
    id: 77,
    title: "Shirt In Botanical Cheetah",
    imgSrc: "/assets/images/home/demo4/product-13.jpg",
    price: 129,
    backgroundColor: "#f1f3e9",
  },
  {
    id: 78,
    title: "Cropped Faux Leather Jacket",
    imgSrc: "/assets/images/home/demo4/product-9.jpg",
    price: 29,
    backgroundColor: "#e9e7e8",
  },
  {
    id: 79,
    title: "Calvin Shorts",
    imgSrc: "/assets/images/home/demo4/product-10.jpg",
    price: 62,
    backgroundColor: "#e9e7e8",
  },
  {
    id: 80,
    title: "Kriby T-Shirt",
    imgSrc: "/assets/images/home/demo4/product-11.jpg",
    price: 17,
    backgroundColor: "#edecea",
  },
  {
    id: 81,
    title: "Cableknit Shawl",
    imgSrc: "/assets/images/home/demo4/product-12.jpg",
    price: 129,
    backgroundColor: "#e8e8e8",
  },
  {
    id: 82,
    title: "Shirt In Botanical Cheetah",
    imgSrc: "/assets/images/home/demo4/product-13.jpg",
    price: 129,
    backgroundColor: "#f1f3e9",
  },
];

export const products9 = [
  {
    id: 83,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Featured",
  },
  {
    id: 84,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Featured",
  },
  {
    id: 85,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Featured",
  },
  {
    id: 86,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Featured",
  },
  {
    id: 87,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Featured",
  },
  {
    id: 88,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Popular",
  },
  {
    id: 89,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Popular",
  },
  {
    id: 90,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Popular",
  },
  {
    id: 91,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Popular",
  },
  {
    id: 92,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Popular",
  },
  {
    id: 93,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Best Rated",
  },
  {
    id: 94,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Best Rated",
  },
  {
    id: 95,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Best Rated",
  },
  {
    id: 96,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Best Rated",
  },
  {
    id: 97,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Best Rated",
  },
  {
    id: 98,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Featured",
  },
  {
    id: 99,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Featured",
  },
  {
    id: 100,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Featured",
  },
  {
    id: 101,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Featured",
  },
  {
    id: 102,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Featured",
  },
  {
    id: 103,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Popular",
  },
  {
    id: 104,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Popular",
  },
  {
    id: 105,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Popular",
  },
  {
    id: 106,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Popular",
  },
  {
    id: 107,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Popular",
  },
  {
    id: 108,
    imgSrc: "/assets/images/home/demo5/product-2-1.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    filterCategory: "Best Rated",
  },
  {
    id: 109,
    imgSrc: "/assets/images/home/demo5/product-0-1.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    filterCategory: "Best Rated",
  },
  {
    id: 110,
    imgSrc: "/assets/images/home/demo5/product-3-1.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Best Rated",
  },
  {
    id: 111,
    imgSrc: "/assets/images/home/demo5/product-4-1.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    priceOld: 129,
    price: 99,
    filterCategory: "Best Rated",
  },
  {
    id: 112,
    imgSrc: "/assets/images/home/demo5/product-1-1.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    filterCategory: "Best Rated",
  },
];
export const lookbookItems = [
  {
    id: 113,
    imgSrc: "/assets/images/home/demo5/lookbook-1.jpg",
    category: "Women Seasons",
    title: "Floral Dress",
    price: 59,
  },
  {
    id: 114,
    imgSrc: "/assets/images/home/demo5/lookbook-2.jpg",
    category: "Women Seasons",
    title: "Trench Coat",
    price: 89,
  },
  {
    id: 115,
    imgSrc: "/assets/images/home/demo5/lookbook-3.jpg",
    category: "Men Seasons",
    title: "Folk Pants",
    price: 79,
  },
  {
    id: 116,
    imgSrc: "/assets/images/home/demo5/lookbook-4.jpg",
    category: "Men Seasons",
    title: "Cos Jacket",
    price: 99,
  },
];
export const products10 = [
  {
    id: 117,
    imgSrc: "/assets/images/home/demo5/product-5-1.jpg",
    category: "Dresses",
    title: "Hub Accent Mirror",
    price: 29,
  },
  {
    id: 118,
    imgSrc: "/assets/images/home/demo5/product-6-1.jpg",
    category: "Dresses",
    title: "Hosking Blue Area Rug",
    price: 62,
  },
  {
    id: 119,
    imgSrc: "/assets/images/home/demo5/product-7-1.jpg",
    category: "Dresses",
    title: "Hanneman Pouf",
    price: 17,
  },
  {
    id: 120,
    imgSrc: "/assets/images/home/demo5/product-8-1.jpg",
    category: "Dresses",
    title: "Cushion Futon Slipcover",
    priceOld: 129,
    price: 99,
  },
  {
    id: 121,
    imgSrc: "/assets/images/home/demo5/product-5-1.jpg",
    category: "Dresses",
    title: "Hub Accent Mirror",
    price: 29,
  },
  {
    id: 122,
    imgSrc: "/assets/images/home/demo5/product-6-1.jpg",
    category: "Dresses",
    title: "Hosking Blue Area Rug",
    price: 62,
  },
  {
    id: 123,
    imgSrc: "/assets/images/home/demo5/product-7-1.jpg",
    category: "Dresses",
    title: "Hanneman Pouf",
    price: 17,
  },
  {
    id: 124,
    imgSrc: "/assets/images/home/demo5/product-8-1.jpg",
    category: "Dresses",
    title: "Cushion Futon Slipcover",
    priceOld: 129,
    price: 99,
  },
];
export const products11 = [
  {
    id: 125,
    imgSrc: "/assets/images/home/demo5/product-10-1.jpg",
    imgAlt: "Cropped Faux leather Jacket",
    title: "Cropped Faux Leather Jacket",
    price: 29,
  },
  {
    id: 126,
    imgSrc: "/assets/images/home/demo5/product-11-1.jpg",
    imgAlt: "Calvin Shorts",
    title: "Calvin Shorts",
    price: 62,
  },
  {
    id: 127,
    imgSrc: "/assets/images/home/demo5/product-12-1.jpg",
    imgAlt: "Kriby T-Shirt",
    title: "Kriby T-Shirt",
    price: 62,
  },
  {
    id: 128,
    imgSrc: "/assets/images/home/demo5/product-13-1.jpg",
    imgAlt: "Cableknit Shawl",
    title: "Cableknit Shawl",
    price: 99,
  },
  {
    id: 129,
    imgSrc: "/assets/images/home/demo5/product-10-1.jpg",
    imgAlt: "Cropped Faux leather Jacket",
    title: "Cropped Faux Leather Jacket",
    price: 29,
  },
  {
    id: 130,
    imgSrc: "/assets/images/home/demo5/product-11-1.jpg",
    imgAlt: "Calvin Shorts",
    title: "Calvin Shorts",
    price: 62,
  },
  {
    id: 131,
    imgSrc: "/assets/images/home/demo5/product-12-1.jpg",
    imgAlt: "Kriby T-Shirt",
    title: "Kriby T-Shirt",
    price: 62,
  },
  {
    id: 132,
    imgSrc: "/assets/images/home/demo5/product-13-1.jpg",
    imgAlt: "Cableknit Shawl",
    title: "Cableknit Shawl",
    price: 99,
  },
];
export const products12 = [
  {
    id: 133,
    style: {
      left: "19.5%",
      top: "29%",
    },
    imgSrc: "/assets/images/home/demo7/product-10.jpg",
    price: 129,
    title: "Cableknit Shawl",
  },
  {
    id: 134,
    style: {
      left: "20%",
      top: "63%",
    },
    imgSrc: "/assets/images/home/demo7/product-10.jpg",
    price: 129,
    title: "Cableknit Shawl",
  },
  {
    id: 135,
    style: {
      right: "28%",
      top: "6%",
    },
    imgSrc: "/assets/images/home/demo7/product-10.jpg",
    price: 129,
    title: "Cableknit Shawl",
  },
  {
    id: 136,
    style: {
      right: "22%",
      top: "20%",
    },
    imgSrc: "/assets/images/home/demo7/product-10.jpg",
    price: 129,
    title: "Cableknit Shawl",
  },
  {
    id: 137,
    style: {
      right: "26%",
      top: "60%",
    },
    imgSrc: "/assets/images/home/demo7/product-10.jpg",
    price: 129,
    title: "Cableknit Shawl",
  },
];
export const products13 = [
  {
    id: 138,
    imgSrc: "/assets/images/home/demo7/product-1-1.jpg",
    img2Src: "/assets/images/home/demo7/product-1-2.jpg",
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 139,
    imgSrc: "/assets/images/home/demo7/product-2-1.jpg",
    img2Src: "/assets/images/home/demo7/product-2-2.jpg",
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 140,
    imgSrc: "/assets/images/home/demo7/product-3-1.jpg",
    img2Src: "/assets/images/home/demo7/product-3-2.jpg",
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 141,
    imgSrc: "/assets/images/home/demo7/product-4-1.jpg",
    img2Src: "/assets/images/home/demo7/product-4-2.jpg",
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 142,
    imgSrc: "/assets/images/home/demo7/product-5-1.jpg",
    img2Src: "/assets/images/home/demo7/product-5-2.jpg",
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 143,
    imgSrc: "/assets/images/home/demo7/product-6-1.jpg",
    img2Src: "/assets/images/home/demo7/product-6-2.jpg",
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 144,
    imgSrc: "/assets/images/home/demo7/product-7-1.jpg",
    img2Src: "/assets/images/home/demo7/product-7-2.jpg",
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
  {
    id: 145,
    imgSrc: "/assets/images/home/demo7/product-8-1.jpg",
    img2Src: "/assets/images/home/demo7/product-8-2.jpg",
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    swatches: [
      {
        color: "#f5e6e0",
        active: false,
      },
      {
        color: "#b9a16b",
        active: true,
      },
      {
        color: "#222222",
        active: false,
      },
    ],
  },
];
export const products14 = [
  {
    id: 146,
    imgSrc: "/assets/images/home/demo7/product-11.jpg",
    title: "Loose Fit Sports Shirt",
    price: 17,
  },
  {
    id: 147,
    imgSrc: "/assets/images/home/demo7/product-12.jpg",
    title: "Seamless Leggings High Waist",
    price: 129,
  },
  {
    id: 148,
    imgSrc: "/assets/images/home/demo7/product-13.jpg",
    title: "Cropped Sports Top",
    price: 129,
  },
  {
    id: 149,
    imgSrc: "/assets/images/home/demo7/product-14.jpg",
    title: "Sports Tank Top",
    price: 17,
  },
  {
    id: 150,
    imgSrc: "/assets/images/home/demo7/product-15.jpg",
    title: "Stainless Steel Water Bottle",
    price: 129,
  },
  {
    id: 151,
    imgSrc: "/assets/images/home/demo7/product-16.jpg",
    title: "Hooded Track Jacket",
    price: 129,
  },
];
export const products15 = [
  {
    id: 152,
    imgSrc: "/assets/images/home/demo7/product-9.jpg",
    category: "Bags And Backpacks",
    title: "Backpack with studs",
    price: 129,
  },
  {
    id: 153,
    imgSrc: "/assets/images/home/demo7/product-9.jpg",
    category: "Bags And Backpacks",
    title: "Backpack with studs",
    price: 129,
  },
  {
    id: 154,
    imgSrc: "/assets/images/home/demo7/product-9.jpg",
    category: "Bags And Backpacks",
    title: "Backpack with studs",
    price: 129,
  },
  {
    id: 155,
    imgSrc: "/assets/images/home/demo7/product-9.jpg",
    category: "Bags And Backpacks",
    title: "Backpack with studs",
    price: 129,
  },
];
export const products16 = [
  {
    id: 156,
    category: "Accessories",
    title: "Wooden Wall Clock",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 157,
    category: "Furniture",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-2-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 158,
    category: "Furniture",
    title: "Small Gray Table",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-3-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 159,
    category: "Accessories",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 160,
    category: "Accessories",
    title: "Black Vase",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-5-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 161,
    category: "Furniture",
    title: "Chest Of Drawers",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-6-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 162,
    category: "Furniture",
    title: "White Table Lamp",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-7-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 163,
    category: "Accessories",
    title: "Minimal Circle Clock",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-8-1.jpg",
    filterCategory: "Featured",
  },
  {
    id: 164,
    category: "Furniture",
    title: "Chest Of Drawers",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-6-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 165,
    category: "Accessories",
    title: "Wooden Wall Clock",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 166,
    category: "Accessories",
    title: "Black Vase",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-5-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 167,
    category: "Furniture",
    title: "Small Gray Table",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-3-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 168,
    category: "Furniture",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-2-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 169,
    category: "Accessories",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 170,
    category: "Furniture",
    title: "White Table Lamp",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-7-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 171,
    category: "Accessories",
    title: "Minimal Circle Clock",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-8-1.jpg",
    filterCategory: "Best Seller",
  },
  {
    id: 172,
    category: "Accessories",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 173,
    category: "Furniture",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-2-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 174,
    category: "Furniture",
    title: "White Table Lamp",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-7-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 175,
    category: "Accessories",
    title: "Minimal Circle Clock",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo9/product-8-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 176,
    category: "Accessories",
    title: "Wooden Wall Clock",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-1-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 177,
    category: "Accessories",
    title: "Black Vase",
    price: 29,
    imgSrc: "/assets/images/home/demo9/product-5-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 178,
    category: "Furniture",
    title: "Chest Of Drawers",
    price: 62,
    imgSrc: "/assets/images/home/demo9/product-6-1.jpg",
    filterCategory: "Sales",
  },
  {
    id: 179,
    category: "Furniture",
    title: "Small Gray Table",
    price: 17,
    imgSrc: "/assets/images/home/demo9/product-3-1.jpg",
    filterCategory: "Sales",
  },
];
export const products17 = [
  {
    id: 180,
    style: {
      left: "12%",
      top: "50%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/oud-roses-2.png",
    price: 350,
    title: "Oud & Roses Gift Set",
  },
  {
    id: 181,
    style: {
      left: "28%",
      top: "55%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/oud-roses-2.png",
    price: 350,
    title: "Oud & Roses Gift Set",
  },
  {
    id: 182,
    style: {
      left: "45%",
      top: "42%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/01/antee.png",
    price: 225,
    title: "Antee Gift Set",
  },
  {
    id: 183,
    style: {
      left: "63%",
      top: "38%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/01/antee.png",
    price: 225,
    title: "Antee Gift Set",
  },
  {
    id: 184,
    style: {
      left: "85%",
      top: "60%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2022/03/Front-box-with-product.png",
    price: 150,
    title: "The Dukhoon Collection",
  },
  // {
  //   id: 185,
  //   style: {
  //     left: "88%",
  //     top: "79%",
  //   },
  //   imgSrc: "/assets/images/home/demo7/product-10.jpg",
  //   price: 129,
  //   title: "Cableknit Shawl",
  // },
];
export const products17mob = [
  {
    id: 180,
    style: {
      left: "17%",
      top: "50%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/oud-roses-2.png",
    price: 123,
    title: "Oud & Roses Gift Set",
  },
  {
    id: 181,
    style: {
      left: "48%",
      top: "32%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/03/antee.png",
    price: 129,
    title: "Antee Gift Set",
  },
  {
    id: 182,
    style: {
      left: "74%",
      top: "43%",
    },
    imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/01/antee.png",
    price: 129,
    title: "Antee Gift Set",
  },
  // {
  //   id: 183,
  //   style: {
  //     left: "63%",
  //     top: "38%",
  //   },
  //   imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2024/01/antee.png",
  //   price: 129,
  //   title: "Antee Gift Set",
  // },
  // {
  //   id: 184,
  //   style: {
  //     left: "85%",
  //     top: "75%",
  //   },
  //   imgSrc: "https://www.ahmedalmaghribi.com/wp-content/uploads/2022/03/Front-box-with-product.png",
  //   price: 129,
  //   title: "The Dukhoon Collection",
  // },
  // {
  //   id: 185,
  //   style: {
  //     left: "88%",
  //     top: "79%",
  //   },
  //   imgSrc: "/assets/images/home/demo7/product-10.jpg",
  //   price: 129,
  //   title: "Cableknit Shawl",
  // },
];
export const products18 = [
  {
    id: 186,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
  },
  {
    id: 187,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
  },
  {
    id: 188,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
  },
  {
    id: 189,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
  },
  {
    id: 190,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
  },
  {
    id: 191,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
  },
  {
    id: 192,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
  },
  {
    id: 193,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
  },
  {
    id: 194,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
  },
  {
    id: 195,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
  },
  {
    id: 196,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
  },
  {
    id: 197,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
  },
  {
    id: 198,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Sportwear",
  },
  {
    id: 199,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Sportwear",
  },
  {
    id: 200,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Sportwear",
  },
  {
    id: 201,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Sportwear",
  },
  {
    id: 202,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Sportwear",
  },
  {
    id: 203,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Sportwear",
  },
  {
    id: 204,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Sportwear",
  },
  {
    id: 205,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Sportwear",
  },
  {
    id: 206,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Sportwear",
  },
  {
    id: 207,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Sportwear",
  },
  {
    id: 208,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Sportwear",
  },
  {
    id: 209,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Sportwear",
  },
  {
    id: 210,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Running",
  },
  {
    id: 211,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Running",
  },
  {
    id: 212,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Running",
  },
  {
    id: 213,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Running",
  },
  {
    id: 214,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Running",
  },
  {
    id: 215,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Running",
  },
  {
    id: 216,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Running",
  },
  {
    id: 217,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Running",
  },
  {
    id: 218,
    imgSrc: "/assets/images/home/demo10/product-4-1.jpg",
    altText: "Cableknit Shawl",
    category: "Hummel",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    filterCategory: "Running",
  },
  {
    id: 219,
    imgSrc: "/assets/images/home/demo10/product-1-1.jpg",
    altText: "Cropped Faux leather Jacket",
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    filterCategory: "Running",
  },
  {
    id: 220,
    imgSrc: "/assets/images/home/demo10/product-3-1.jpg",
    altText: "Indy Icon Clash",
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    filterCategory: "Running",
  },
  {
    id: 221,
    imgSrc: "/assets/images/home/demo10/product-2-1.jpg",
    altText: "Gray Vintage Chair",
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    filterCategory: "Running",
  },
];

export const products19 = [
  {
    id: 222,
    style: {
      left: "28%",
      top: "17%",
    },
    imgSrc: "/assets/images/home/demo10/product-5-1.jpg",
    price: 129,
    oldPrice: 189,
    category: "Hummel",
    title: "Cableknit Shawl",
  },
  {
    id: 223,
    style: {
      left: "28%",
      top: "44%",
    },
    imgSrc: "/assets/images/home/demo10/product-5-1.jpg",
    price: 129,
    oldPrice: 189,
    category: "Hummel",
    title: "Cableknit Shawl",
  },
  {
    id: 224,
    style: {
      left: "44%",
      top: "71%",
    },
    imgSrc: "/assets/images/home/demo10/product-5-1.jpg",
    price: 129,
    oldPrice: 189,
    category: "Hummel",
    title: "Cableknit Shawl",
  },
];
export const products20 = [
  {
    id: 225,
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    imgSrc: "/assets/images/home/demo10/product-6-1.jpg",
  },
  {
    id: 226,
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/home/demo10/product-7-1.jpg",
  },
  {
    id: 227,
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    imgSrc: "/assets/images/home/demo10/product-8-1.jpg",
  },
  {
    id: 228,
    category: "Hummel",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo10/product-9-1.jpg",
  },
  {
    id: 229,
    category: "Nike",
    title: "Wildhorse 6",
    price: 29,
    imgSrc: "/assets/images/home/demo10/product-6-1.jpg",
  },
  {
    id: 230,
    category: "Puma",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/home/demo10/product-7-1.jpg",
  },
  {
    id: 231,
    category: "Reebok",
    title: "Indy Icon Clash",
    price: 17,
    imgSrc: "/assets/images/home/demo10/product-8-1.jpg",
  },
  {
    id: 232,
    category: "Hummel",
    title: "Cableknit Shawl",
    oldPrice: 129,
    price: 99,
    imgSrc: "/assets/images/home/demo10/product-9-1.jpg",
  },
];

export const products51 = [
  {
    id: 233,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    priceOld: 10,
    imgSrc: "/assets/images/products/product_1.jpg",
    imgSrc2: "/assets/images/products/product_1-1.jpg",
    reviews: "8k+ reviews",
    rating: 5,
    filterCategory2: "Women",
    filterCategory: "New Arrivals",
  },
  {
    id: 234,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product_2.jpg",
    imgSrc2: "/assets/images/products/product_2-1.jpg",
    colors: ["#444", "#e344a2", "#000"],
    discont: 67,
    filterCategory2: "Jackets",
    filterCategory: "Best Seller",
  },
  {
    id: 235,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_3.jpg",
    imgSrc2: "/assets/images/products/product_3-1.jpg",
    filterCategory2: "Accessories",
    filterCategory: "New Arrivals",
  },
  {
    id: 236,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_4.jpg",
    imgSrc2: "/assets/images/products/product_4-1.jpg",
    isNew: true,
    filterCategory2: "Jackets",
    filterCategory: "Best Seller",
  },
  {
    id: 237,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_5.jpg",
    imgSrc2: "/assets/images/products/product_5-1.jpg",
    filterCategory2: "Jackets",
    filterCategory: "New Arrivals",
  },
  {
    id: 238,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/product_6.jpg",
    imgSrc2: "/assets/images/products/product_6-1.jpg",
    filterCategory2: "StayHome",
    filterCategory: "New Arrivals",
  },
  {
    id: 239,
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_7.jpg",
    imgSrc2: "/assets/images/products/product_7-1.jpg",
    filterCategory2: "StayHome",
    filterCategory: "Best Seller",
  },
  {
    id: 240,
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_8.jpg",
    imgSrc2: "/assets/images/products/product_8-1.jpg",
    filterCategory2: "Hoodies",
    filterCategory: "New Arrivals",
  },
  {
    id: 241,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_9.jpeg",
    imgSrc2: "/assets/images/products/product_9-1.jpeg",
    reviews: "8k+ reviews",
    rating: 5,
    filterCategory2: "StayHome",
    filterCategory: "Top Rated",
  },
  {
    id: 242,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product_10.jpeg",
    imgSrc2: "/assets/images/products/product_10-1.jpeg",
    filterCategory2: "Hoodies",
    filterCategory: "Top Rated",
  },
  {
    id: 243,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_11.jpeg",
    imgSrc2: "/assets/images/products/product_11-1.jpeg",
    filterCategory2: "Hoodies",
    filterCategory: "New Arrivals",
  },
  {
    id: 244,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_12.jpeg",
    imgSrc2: "/assets/images/products/product_12-1.jpeg",
    filterCategory2: "Shoes",
    filterCategory: "Top Rated",
  },
  {
    id: 245,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_13.jpeg",
    imgSrc2: "/assets/images/products/product_13-1.jpeg",
    filterCategory2: "Men",
    filterCategory: "Top Rated",
  },
  {
    id: 246,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/product_14.jpeg",
    imgSrc2: "/assets/images/products/product_14-1.jpeg",
    filterCategory2: "StayHome",
    filterCategory: "New Arrivals",
  },
  {
    id: 247,
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_15.jpeg",
    imgSrc2: "/assets/images/products/product_15-1.jpeg",
    filterCategory2: "Men",
    filterCategory: "New Arrivals",
  },
  {
    id: 248,
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_16.jpeg",
    imgSrc2: "/assets/images/products/product_16-1.jpeg",
    filterCategory2: "Women",
    filterCategory: "New Arrivals",
  },
];
export const products52 = [
  {
    id: 249,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product-17-1.jpg",
    imgSrc2: "/assets/images/products/product-17-2.jpg",
    reviews: "8k+ reviews",
    rating: 5,
  },
  {
    id: 250,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product-18-1.jpg",
    imgSrc2: "/assets/images/products/product_15.jpeg",
    colors: ["#444", "#e344a2", "#000"],
    discont: 67,
  },
  {
    id: 251,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product-19-1.jpg",
    imgSrc2: "/assets/images/products/product-19-2.jpg",
  },
  {
    id: 252,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product-20-1.jpg",
    imgSrc2: "/assets/images/products/product-20-2.jpg",
    isNew: true,
  },
  {
    id: 253,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product-21-1.jpg",
    imgSrc2: "/assets/images/products/product-21-2.jpg",
  },
  {
    id: 254,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/product-22-1.jpg",
    imgSrc2: "/assets/images/products/product-22-2.jpg",
  },
];
export const products53 = [
  {
    id: 255,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product-23-1.jpg",
    colors: ["#444", "#e344a2", "#000"],
    reviews: "8k+ reviews",
    rating: 5,
  },
  {
    id: 256,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product-18-1.jpg",
    discont: 67,
  },
  {
    id: 257,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_2.jpg",
  },
  {
    id: 258,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product-24-1.jpg",
    isNew: true,
  },
  {
    id: 259,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product-22-1.jpg",
  },
  {
    id: 260,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/product_9.jpeg",
  },
  {
    id: 261,
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_8.jpg",
  },
  {
    id: 262,
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product-25-1.jpg",
  },
  {
    id: 263,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_7.jpg",
    reviews: "8k+ reviews",
    rating: 5,
  },
  {
    id: 264,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product_7.jpg",
  },
  {
    id: 265,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_15.jpeg",
  },
  {
    id: 266,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_16.jpeg",
  },
];
export const products54 = [
  {
    id: 566,
    category: "Oriental Fragrance",
    title: "Oud Lavender",
    price: 135,
    imgSrc: "/assets/images/products/oud-and-lavender.png",
    imgSrc2: "/assets/images/products/product-26-2.jpg",
  },
  {
    id: 567,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/laathani-web.jpg",
    imgSrc2: "/assets/images/products/product-27-2.jpeg",
  },
  {
    id: 568,
    category: "Oriental Fragrance",
    title: "Rose Noir",
    price: 150,
    imgSrc: "/assets/images/products/musk-ahmed.png",
    imgSrc2: "/assets/images/products/product-28-2.jpg",
  },
  {
    id: 569,
    category: "Oriental Fragrance",
    title: "Summer Oud",
    price: 99,
    imgSrc: "/assets/images/products/maani.png",
    imgSrc2: "/assets/images/products/product-29-2.jpg",
  },
  {
    id: 5699,
    category: "Oriental Fragrance",
    title: "Kaaf",
    price: 99,
    imgSrc: "/assets/images/products/kaaf-notes.png",
    imgSrc2: "/assets/images/products/product-29-2.jpg",
  },
  {
    id: 5690,
    category: "Oriental Fragrance",
    title: "Gardenia",
    price: 99,
    imgSrc: "/assets/images/products/gardenia-notes.png",
    imgSrc2: "/assets/images/products/product-29-2.jpg",
  },
];

export const products55 = [
  {
    id: 570,
    category: "Dresses",
    title: "Cropped Faux Leather Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_9.jpeg",
    imgSrc2: "/assets/images/products/product_9-1.jpeg",
    reviews: "8k+ reviews",
    rating: 5,
    filterCategory: "Top Rated",
  },
  {
    id: 571,
    category: "Dresses",
    title: "Calvin Shorts",
    price: 62,
    imgSrc: "/assets/images/products/product_10.jpeg",
    imgSrc2: "/assets/images/products/product_10-1.jpeg",
    filterCategory: "Top Rated",
  },
  {
    id: 572,
    category: "Dresses",
    title: "Kirby T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_11.jpeg",
    imgSrc2: "/assets/images/products/product_11-1.jpeg",
    filterCategory: "New Arrivals",
  },
  {
    id: 573,
    category: "Dresses",
    title: "Cableknit Shawl",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_12.jpeg",
    imgSrc2: "/assets/images/products/product_12-1.jpeg",
    filterCategory: "Top Rated",
  },
];
export const products56 = [
  {
    id: 574,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product_13.jpeg",
    imgSrc2: "/assets/images/products/product-22-1.jpg",
    filterCategory: "Top Rated",
  },
  {
    id: 575,
    category: "Dresses",
    title: "Shirt In Botanical Cheetah Print",
    price: 62,
    imgSrc: "/assets/images/products/product_14.jpeg",
    imgSrc2: "/assets/images/products/product_14-1.jpeg",
    filterCategory: "New Arrivals",
  },
  {
    id: 576,
    category: "Dresses",
    title: "Cotton Jersey T-Shirt",
    price: 17,
    imgSrc: "/assets/images/products/product_15.jpeg",
    imgSrc2: "/assets/images/products/product_15-1.jpeg",
    filterCategory: "New Arrivals",
  },
  {
    id: 577,
    category: "Dresses",
    title: "Zessi Dresses",
    priceOld: 129,
    price: 99,
    imgSrc: "/assets/images/products/product_16.jpeg",
    imgSrc2: "/assets/images/products/product_16-1.jpeg",
    filterCategory: "New Arrivals",
  },
];

export const products57 = [
  {
    id: 578,
    category: "NIKE",
    title: "Wildhorse 6",
    price: 29,
    imgSrc: "/assets/images/products/product-30-1.jpg",
    imgSrc2: "/assets/images/products/product-30-1.jpg",
  },
  {
    id: 579,
    category: "PUMA",
    title: "Gray Vintage Chair",
    price: 62,
    imgSrc: "/assets/images/products/product-31-1.jpg",
    imgSrc2: "/assets/images/products/product_6-1.jpg",
  },
  {
    id: 580,
    category: "REEBOK",
    title: "Indy Icon Clash",
    price: 99,
    imgSrc: "/assets/images/products/product-32-1.jpg",
    imgSrc2: "/assets/images/products/product_7-1.jpg",
  },
  {
    id: 581,
    category: "Dresses",
    title: "Colorful Jacket",
    price: 29,
    imgSrc: "/assets/images/products/product-33-1.jpg",
    imgSrc2: "/assets/images/products/product_10.jpeg",
  },
];
export const products58 = [
  {
    id: 582,
    category: "Television & Video",
    title: "Toshiba TF-43A810U21 43-inch 4K UHD TV - Fire TV Edition",
    price: 29,
    imgSrc: "/assets/images/products/product-37-1.jpg",
    rating: 5,
    reviews: 321975,
  },
  {
    id: 583,
    category: "Camera & Photo",
    title:
      "Wyze Cam 1080p HD Indoor Wireless Smart Home Camera with Night Vision...",
    price: 62,
    imgSrc: "/assets/images/products/product-35-1.jpg",
    rating: 5,
    reviews: 321975,
  },
  {
    id: 584,
    category: "Audio",
    title: "NUBWO Gaming headsets PS4 N7 Stereo Xbox one Headset Gaming...",
    price: 17,
    imgSrc: "/assets/images/products/product-36-1.jpg",
    rating: 5,
    reviews: 321975,
  },
  {
    id: 585,
    category: "Bluetooth Speakers",
    title: "Echo Dot (3rd Gen) - Smart speaker with Alexa - Charcoal",
    price: 99,
    imgSrc: "/assets/images/products/product-37-1.jpg",
    rating: 5,
    reviews: 321975,
  },
];

export const products59 = [
  {
    id: 586,
    imgSrc: "/assets/images/products/product-38-1.jpg",
    category: "Fruits",
    title: "Organic Bartlett Pear",
    reviewCount: "321,975",
    price: 35.9,
    stars: 5,
  },
  {
    id: 587,
    imgSrc: "/assets/images/products/product-39-1.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    reviewCount: "321,975",
    price: 79.99,
    stars: 5,
  },
  {
    id: 588,
    imgSrc: "/assets/images/products/product-40-1.jpg",
    category: "Fruits",
    title: "Blueberry Organic, 6 Ounce",
    reviewCount: "321,975",
    price: 929,
    stars: 5,
  },
  {
    id: 589,
    imgSrc: "/assets/images/products/product-41-1.jpg",
    category: "Fruits",
    title: "Kiwi Organic, 1 Each",
    reviewCount: "321,975",
    price: 729,
    stars: 5,
  },
  {
    id: 590,
    imgSrc: "/assets/images/products/product-42-1.jpg",
    category: "Fruits",
    title: "Organic Banana",
    reviewCount: "321,975",
    price: 399,
    stars: 5,
  },
];
