export const products35 = [
  {
    id: 435,
    imgSrc: "/assets/images/home/demo16/product-1.jpg",
    title: "VALENCIA BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Most Viewed",
  },
  {
    id: 436,
    imgSrc: "/assets/images/home/demo16/product-2.jpg",
    title: "PARIS BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Bestsellers",
  },
  {
    id: 437,
    imgSrc: "/assets/images/home/demo16/product-3.jpg",
    title: "NEW YORK BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Most Viewed",
  },
  {
    id: 438,
    imgSrc: "/assets/images/home/demo16/product-4.jpg",
    title: "LONDON BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Most Viewed",
  },
  {
    id: 439,
    imgSrc: "/assets/images/home/demo16/product-5.jpg",
    title: "VALENCIA BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Bestsellers",
  },
  {
    id: 440,
    imgSrc: "/assets/images/home/demo16/product-6.jpg",
    title: "PARIS BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Bestsellers",
  },
  {
    id: 441,
    imgSrc: "/assets/images/home/demo16/product-7.jpg",
    title: "NEW YORK BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Most Viewed",
  },
  {
    id: 442,
    imgSrc: "/assets/images/home/demo16/product-8.jpg",
    title: "LONDON BIKE",
    category: "BIKES",
    price: 29,
    ratings: 5,
    filterCategory: "Bestsellers",
  },
];

export const products36 = [
  {
    id: 443,
    category: "BIKES",
    title: "NEW YORK BIKE",
    price: 29,
    imgSrc: "/assets/images/home/demo16/banner-4.jpg",
  },
  {
    id: 444,
    category: "BIKES",
    title: "NEW YORK BIKE",
    price: 29,
    imgSrc: "/assets/images/home/demo16/banner-4.jpg",
  },
];

export const products37 = [
  {
    id: 445,
    title: "VALENCIA BIKE",
    imgSrc: "/assets/images/home/demo16/product-5.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 446,
    title: "PARIS BIKE",
    imgSrc: "/assets/images/home/demo16/product-6.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 447,
    title: "NEW YORK BIKE",
    imgSrc: "/assets/images/home/demo16/product-7.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 448,
    title: "LONDON BIKE",
    imgSrc: "/assets/images/home/demo16/product-8.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 449,
    title: "VALENCIA BIKE",
    imgSrc: "/assets/images/home/demo16/product-5.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 450,
    title: "PARIS BIKE",
    imgSrc: "/assets/images/home/demo16/product-6.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 451,
    title: "NEW YORK BIKE",
    imgSrc: "/assets/images/home/demo16/product-7.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
  {
    id: 452,
    title: "LONDON BIKE",
    imgSrc: "/assets/images/home/demo16/product-8.jpg",
    category: "BIKES",
    price: 29,
    rating: 5,
  },
];
