export const products40 = [
  {
    id: 453,
    imgSrc: "/assets/images/home/demo18/product-1.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 454,
    imgSrc: "/assets/images/home/demo18/product-2.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Most Viewed",
  },
  {
    id: 455,
    imgSrc: "/assets/images/home/demo18/product-3.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Most Viewed",
  },
  {
    id: 456,
    imgSrc: "/assets/images/home/demo18/product-4.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 457,
    imgSrc: "/assets/images/home/demo18/product-5.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 458,
    imgSrc: "/assets/images/home/demo18/product-1.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Most Viewed",
  },
  {
    id: 459,
    imgSrc: "/assets/images/home/demo18/product-2.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 460,
    imgSrc: "/assets/images/home/demo18/product-3.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 461,
    imgSrc: "/assets/images/home/demo18/product-4.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Bestsellers",
  },
  {
    id: 462,
    imgSrc: "/assets/images/home/demo18/product-5.jpg",
    category: "Cars",
    title: "12v Compact Battery Jump Starter",
    price: 35.9,
    rating: 5,
    ratingCount: 32400,
    filterCategory: "Most Viewed",
  },
];
