export const products25 = [
  {
    id: 316,
    imgSrc: "/assets/images/home/demo12/product-1.jpg",
    title: "Organic Bartlett Pear",
    category: "Fruits",
    rating: 4.5,
    reviews: 321975,
    price: 35.9,
    filterCategory: "Most Popular",
  },
  {
    id: 317,
    imgSrc: "/assets/images/home/demo12/product-2.jpg",
    title: "Organic Strawberries, 1 lb",
    category: "Fruits",
    rating: 4.5,
    reviews: 321975,
    price: 79.99,
    filterCategory: "Best Rated",
  },
  {
    id: 318,
    imgSrc: "/assets/images/home/demo12/product-3.jpg",
    title: "Blueberry Organic, 6 Ounce",
    category: "Fruits",
    rating: 4.5,
    reviews: 321975,
    price: 929,
    filterCategory: "Most Popular",
  },
  {
    id: 319,
    imgSrc: "/assets/images/home/demo12/product-4.jpg",
    title: "Some Product Name",
    category: "Category",
    rating: 4.5,
    reviews: 321975,
    price: 999.99,
    filterCategory: "Most Popular",
  },
  {
    id: 320,
    imgSrc: "/assets/images/home/demo12/product-5.jpg",
    category: "Fruits",
    title: "Organic Banana",
    rating: 5,
    reviews: 321975,
    price: 399,
    filterCategory: "Best Rated",
  },
  {
    id: 321,
    imgSrc: "/assets/images/home/demo12/product-6.jpg",
    category: "Fruits",
    title: "McCormick Gourmet Orange",
    rating: 5,
    reviews: 321975,
    price: 35.9,
    filterCategory: "Best Rated",
  },
  {
    id: 322,
    imgSrc: "/assets/images/home/demo12/product-7.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    rating: 5,
    reviews: 321975,
    price: 79.99,
    filterCategory: "Best Rated",
  },
  {
    id: 323,
    imgSrc: "/assets/images/home/demo12/product-8.jpg",
    category: "Fruits",
    title: "Some Other Product",
    rating: 5,
    reviews: 321975,
    price: 99.99,
    filterCategory: "Most Popular",
  },
  {
    id: 324,
    imgSrc: "/assets/images/home/demo12/product-9.jpg",
    category: "Fruits",
    title: "Dried Pineapple Fruit Bar",
    rating: 5,
    reviews: 321975,
    price: 729,
    filterCategory: "Most Popular",
  },
  {
    id: 325,
    imgSrc: "/assets/images/home/demo12/product-10.jpg",
    category: "Fruits",
    title: "Holiday Nuts Gift Basket",
    rating: 5,
    reviews: 321975,
    price: 399,
    filterCategory: "Most Popular",
  },
];

export const products26 = [
  {
    id: 326,
    imgSrc: "/assets/images/home/demo12/product-11.jpg",
    category: "Fruits",
    title: "Organic Bartlett Pear",
    stars: 4,
    reviews: 321975,
    price: 35.9,
  },
  {
    id: 327,
    imgSrc: "/assets/images/home/demo12/product-12.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    stars: 4,
    reviews: 321975,
    price: 79.99,
  },
  {
    id: 328,
    imgSrc: "/assets/images/home/demo12/product-13.jpg",
    category: "Fruits",
    title: "Blueberry Organic, 6 Ounce",
    stars: 4,
    reviews: 321975,
    price: 929,
  },
  {
    id: 329,
    imgSrc: "/assets/images/home/demo12/product-14.jpg",
    category: "Fruits",
    title: "Kiwi Organic, 1 Each",
    stars: 4,
    reviews: 321975,
    price: 729,
  },
  {
    id: 330,
    imgSrc: "/assets/images/home/demo12/product-15.jpg",
    category: "Fruits",
    title: "Organic Banana",
    stars: 4,
    reviews: 321975,
    price: 399,
  },
  {
    id: 331,
    imgSrc: "/assets/images/home/demo12/product-11.jpg",
    category: "Fruits",
    title: "Organic Bartlett Pear",
    stars: 4,
    reviews: 321975,
    price: 35.9,
  },
  {
    id: 332,
    imgSrc: "/assets/images/home/demo12/product-12.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    stars: 4,
    reviews: 321975,
    price: 79.99,
  },
  {
    id: 333,
    imgSrc: "/assets/images/home/demo12/product-13.jpg",
    category: "Fruits",
    title: "Blueberry Organic, 6 Ounce",
    stars: 4,
    reviews: 321975,
    price: 929,
  },
  {
    id: 334,
    imgSrc: "/assets/images/home/demo12/product-14.jpg",
    category: "Fruits",
    title: "Kiwi Organic, 1 Each",
    stars: 4,
    reviews: 321975,
    price: 729,
  },
  {
    id: 335,
    imgSrc: "/assets/images/home/demo12/product-15.jpg",
    category: "Fruits",
    title: "Organic Banana",
    stars: 4,
    reviews: 321975,
    price: 399,
  },
];
export const products27 = [
  {
    id: 336,
    imgSrc: "/assets/images/home/demo12/product-16.jpg",
    category: "Fruits",
    title: "Organic Bartlett Pear",
    rating: 5,
    reviews: 321975,
    price: 35.9,
  },
  {
    id: 337,
    imgSrc: "/assets/images/home/demo12/product-17.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    rating: 5,
    reviews: 321975,
    price: 79.99,
  },
  {
    id: 338,
    imgSrc: "/assets/images/home/demo12/product-18.jpg",
    category: "Fruits",
    title: "Blueberry Organic, 6 Ounce",
    rating: 5,
    reviews: 321975,
    price: 929,
  },
  {
    id: 339,
    imgSrc: "/assets/images/home/demo12/product-19.jpg",
    category: "Fruits",
    title: "Kiwi Organic, 1 lb",
    rating: 5,
    reviews: 321975,
    price: 42.5,
  },
  {
    id: 340,
    imgSrc: "/assets/images/home/demo12/product-16.jpg",
    category: "Fruits",
    title: "Organic Bartlett Pear",
    rating: 5,
    reviews: 321975,
    price: 35.9,
  },
  {
    id: 341,
    imgSrc: "/assets/images/home/demo12/product-17.jpg",
    category: "Fruits",
    title: "Organic Strawberries, 1 lb",
    rating: 5,
    reviews: 321975,
    price: 79.99,
  },
  {
    id: 342,
    imgSrc: "/assets/images/home/demo12/product-18.jpg",
    category: "Fruits",
    title: "Blueberry Organic, 6 Ounce",
    rating: 5,
    reviews: 321975,
    price: 929,
  },
  {
    id: 343,
    imgSrc: "/assets/images/home/demo12/product-19.jpg",
    category: "Fruits",
    title: "Kiwi Organic, 1 lb",
    rating: 5,
    reviews: 321975,
    price: 42.5,
  },
];
