export const products33 = [
  {
    id: 408,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 409,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 410,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 411,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 412,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 413,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 414,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 415,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 416,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 417,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 418,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 419,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 420,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 421,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 422,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 423,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Face",
  },
  {
    id: 424,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 425,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 426,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 427,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Eyes",
  },
  {
    id: 428,
    imgSrc: "/assets/images/home/demo15/product-3.jpg",
    category: "Makeup",
    title: "Facefinity All Day Flawless",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 429,
    imgSrc: "/assets/images/home/demo15/product-2.jpg",
    category: "Makeup",
    title: "Laura Face Wash Lotion",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 430,
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    category: "Makeup",
    title: "Radiant Lift Foundation",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
  {
    id: 431,
    imgSrc: "/assets/images/home/demo15/product-1.jpg",
    category: "Makeup",
    title: "Rise & Shine",
    price: 29,
    rating: 5,
    filterCategory: "Lips",
  },
];

export const products34 = [
  {
    id: 432,
    style: {
      left: "6%",
      top: "23%",
    },
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    title: "RISE & SHINE",
    price: 34,
  },
  {
    id: 433,
    style: {
      left: "15%",
      top: "61%",
    },
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    title: "RISE & SHINE",
    price: 34,
  },
  {
    id: 434,
    style: {
      left: "37%",
      top: "83%",
    },
    imgSrc: "/assets/images/home/demo15/product-4.jpg",
    title: "RISE & SHINE",
    price: 34,
  },
];
