export const products42 = [
  {
    id: 471,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 472,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "Women",
  },
  {
    id: 473,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 474,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 475,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "One Sale",
  },
  {
    id: 476,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 477,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 478,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 479,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
  {
    id: 480,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "New",
  },
  {
    id: 481,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
  {
    id: 482,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
  {
    id: 483,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 484,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "Women",
  },
  {
    id: 485,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 486,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "Women",
  },
  {
    id: 487,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "One Sale",
  },
  {
    id: 488,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 489,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 490,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "One Sale",
  },
  {
    id: 491,
    imgSrc: "/assets/images/home/demo20/product-3.jpg",
    title: "Two Stone Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
  {
    id: 492,
    imgSrc: "/assets/images/home/demo20/product-2.jpg",
    title: "Sphere Sterling",
    category: "Jewelry",
    price: 29,
    rating: 5,
    badge: "HOT",
    discount: 67,
    filterCategory: "New",
  },
  {
    id: 493,
    imgSrc: "/assets/images/home/demo20/product-4.jpg",
    title: "Classic Round Cut",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
  {
    id: 494,
    imgSrc: "/assets/images/home/demo20/product-1.jpg",
    title: "Sea Shell Sterling Silver",
    category: "Jewelry",
    price: 29,
    rating: 5,
    filterCategory: "New",
  },
];
