export const products28 = [
  {
    id: 344,
    imgSrc: "/assets/images/products/product-43.png",
    title: "Aviator Classic",
    price: 429,
  },
  {
    id: 345,
    imgSrc: "/assets/images/products/product-44.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
  {
    id: 346,
    imgSrc: "/assets/images/products/product-45.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
  {
    id: 347,
    imgSrc: "/assets/images/products/product-46.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
  {
    id: 348,
    imgSrc: "/assets/images/products/product-43.png",
    title: "Aviator Classic",
    price: 429,
  },
  {
    id: 349,
    imgSrc: "/assets/images/products/product-44.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
  {
    id: 350,
    imgSrc: "/assets/images/products/product-45.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
  {
    id: 351,
    imgSrc: "/assets/images/products/product-46.png",
    title: "New Wayfarer Classic",
    price: 429,
  },
];
